import { useEffect, useState } from "react"
import { domainSettingsForTest } from "../../utils"
import MainScreen from "../main-screen"
import Cookies from 'js-cookie'
import Popups from "../popups"
import FinishSteps from "../finish-steps"
import Step from "../steps"
import Politic from "../politic/politic"

const Router = () => {

    const [step, setStep] = useState(null)
    const [params, setParams] = useState({})
    const [raionsName, setRaionsName] = useState("")
    const [roomsName, setRoomsName] = useState("")
    const [sdachaName, setSdachaName] = useState("")
    const [raionsPrice, setRaionsPrice] = useState("")
    const [sendParams, setSendParams] = useState({
        pipelineId: 0,
        statusId: 0,
        sId: 0,
    })
    const [showPolitic, setShowPolitics] = useState(false)

    const [form, setForm] = useState({})
    const nextStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step===null) {
            setStep(0)
        } else {
            setStep(step + 1)
        }
    }
    const prevStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step - 1 < 0) {
            setStep(0)
        } else {
            setStep(step - 1)
        }
    }

    useEffect(() => {
        if (domainSettings.domain.yandex != "") {
            try {
                setTimeout(() => {
                    ym(parseInt(domainSettings.domain.yandex), 'getClientID', function (clientID) {
                        domainSettings.domain.clientID = clientID
                    })
                }, 3000)
            } catch (e) { }
        }
        setParams(domainSettings.domain)
    }, [])

    const isMobile = () => {
        let check = false;
        if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
            check = true
        }
        return check;
    }

    const getBackground = () => {
        if (isMobile()) {
            return domainSettings.domain.background_mobile
        }
        return domainSettings.domain.background
    }

    useEffect(()=>{
        if (domainSettings.domain.pageTitle) {
            document.querySelector('title').innerHTML = domainSettings.domain.pageTitle
        }
    },[])
    
    return <div className="container_main">
        {showPolitic?<Politic site={domainSettings.domain.url} date={domainSettings.domain.politicsDate!=""?domainSettings.domain.politicsDate:domainSettings.domain.created_at.split(" ")[0]} close={()=>{setShowPolitics(false)}}/>:<></>}
        {(() => {
                switch (true) {
                    case step === null:
                        return <MainScreen params={params} nextStep={nextStep} />
                    case params.steps.length <= step:
                        return <FinishSteps form={form} sendParams={sendParams} setForm={setForm} params={params} setShowPolitics={setShowPolitics} />;
                    default:
                        return <Step step={params.steps[step]} params={params} sendParams={sendParams} setSendParams={setSendParams} index={step} length={params.steps.length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />;
                }
            })()}
        <Popups params={params} />
        <div className="cookie">
          <span>
            Мы используем файлы cookie, чтобы убедиться, что наш веб-сайт
            работает должным образом. Вы можете продолжить пользоваться нашим
            сайтом и принять нашу <a href="#" onClick={(e)=>{e.preventDefault(); setShowPolitics(true);}} target="_blank"> Политику cookie.</a> Изменить ваши настройки cookie можно будет в любой момент.
          </span>
          <a className="cookie_btn" onClick={(e) => { e.preventDefault(); document.querySelector('.cookie').style.display = "none" }}> Я принимаю</a>
        </div>
    </div>
}

export default Router
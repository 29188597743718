import React, { useState, useEffect } from "react"
import CheckBoxItem, { Checkbox } from "./checkbox-item"
import ColoredScrollbars from "../colored-scrollbars/colored-scrollbars";

const TextStep = ({ step, params, index, length, nextStep, prevStep, sendParams, setSendParams, form, setForm }) => {
    const amIChecked = (answer) => {
        return form[step.title].includes(answer.title)
    }

    const updateCheck = (answer) => {
        if (amIChecked(answer)) {
            unsetAnswer(answer)
            if (answer.pipelineId && sendParams.pipelineId === answer.pipelineId) {
                setSendParams({
                    pipelineId: 0,
                    statusId: 0,
                    sId: 0,
                })
            }
        } else {
            setAnswer(answer)
            if (answer.pipelineId) {
                setSendParams({
                    pipelineId: answer.pipelineId,
                    statusId: answer.statusId,
                    sId: answer.sId,
                })
            }
        }
    }

    const setAnswer = (answerIn) => {
        let answers = form[step.title]

        if (answers[0] == "") {
            answers.splice(0, 1)
        }
        answers.push(answerIn.title)

        setForm((prev) => {
            let newForm = {}
            for (let i in prev) {
                newForm[i] = prev[i]
            }
            newForm[step.title] = answers
            return newForm
        })
    }

    const unsetAnswer = (answerOut) => {
        let answers = form[step.title]
        if (answers[0] == "") {
            answers.splice(0, 1)
        }
        for (var key in answers) {
            if (answers[key] === answerOut.title) {
                answers.splice(key, 1);
            }
        }
        let newForm = {}
        for (let i in form) {
            newForm[i] = form[i]
        }

        newForm[step.title] = answers
        setForm(newForm)
    }

    useEffect(() => {
        if (form[step.title] === undefined) {
            setForm({
                ...form,
                [step.title]: [],
            })
        }

    }, [step.title])

    const selectAll = () => {
        let answers = []

        if (isAllSelected()){
            setForm((prev) => {
                let newForm = {}
                for (let i in prev) {
                    newForm[i] = prev[i]
                }
                newForm[step.title] = answers
                return newForm
            })
            return
        }
       
        
        step.answers.forEach(answer => {
            answers.push(answer.title)
        });
        

        setForm((prev) => {
            let newForm = {}
            for (let i in prev) {
                newForm[i] = prev[i]
            }
            newForm[step.title] = answers
            return newForm
        })  
    }

    const isAllSelected = () => {
        let answers = form[step.title]
        if (!answers) {
            return false
        }
        let flag = true

        step.answers.forEach(answer => {
            if (!answers.includes(answer.title)) {
                flag = false
                return false
            }
        });

        return flag
    }

    return <ColoredScrollbars style={{ backgroundColor: "transparent", width: "100%", height: 300 }}>
        <ul className="check_list_one check_list" id="kv_list">
            {step.questionType == 1 ? <div key={"selectall"} style={{ position: "relative", fontSize: "22px", color: "#FFF", lineHeight: "22px" }} className="act">
                <Checkbox params={params} name="tac" checked={isAllSelected()} onChange={() => { selectAll() }} style={{ display: "flex" }}>
                    <span>{"Рассмотрю все"}</span>
                </Checkbox>
            </div> : ""}
            {step.answers.map((answer, i) => {
                //return <CheckBoxItem checked={amIChecked(answer)} updateCheck={updateCheck} key={answer} answer={answer} step={step} params={params} index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />
                return <CheckBoxItem key={i} checked={form[step.title] && form[step.title].includes(answer.title)} updateCheck={updateCheck} params={params} answer={answer} />
            })}
        </ul>
    </ColoredScrollbars>
}

export default TextStep
import React, { useEffect, useState } from 'react';
import CityTitle from '../city-title';
import BtnComponent from '../btn-component';
import SliderStep from './slider-step';
import TextStep from './text-step';
import axios from 'axios';
import { stepChangeDataLayer } from '../../utils/send-data';
import { QuestionType } from "./enums";

const Step = ({ step, params, sendParams, setSendParams, index, length, nextStep, prevStep, raionsStep, roomsStep, form, setForm, }) => {
  const isMobile = () => {
    let check = false;
    if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
      check = true
    }
    return check;
  }

  const getBackground = () => {
    if (isMobile()) {
      return domainSettings.domain.background_mobile
    }
    return domainSettings.domain.background
  }
  return (
    <section className="header" style={{ background: params.background != "" ? `url("/file-store/${getBackground() != "" ? getBackground() : params.background}") center / cover no-repeat` : `url("/templates/plans_template/build/img/hd_bg_0810.jpg")` }}>
      <div
        style={{
          backgroundColor: 'rgba(0,0,0, .56)',
          width: '100%',
          padding: '100px 0px',
        }}
      >
        <div style={{ display: 'block' }} className="hd_inn">
          <div className="page_view">
            <p class="step_ident">Шаг {index + 1}/{length}</p>
            <CityTitle params={params} />
            <div class="title_inner">{step.title}</div>
            {(() => {
                switch (step.questionType) {
                    case QuestionType.SimpleQuestion:
                    case QuestionType.RaionsQuestion:
                    case QuestionType.RoomsQuestion:
                    case QuestionType.PriceQuestionVariants:
                    case QuestionType.CompletionQuestion:
                    case QuestionType.ClassQuestion:
                    case QuestionType.DistrictQuestion:
                        return <TextStep step={step} params={params} sendParams={sendParams} setSendParams={setSendParams}  index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />
                    case QuestionType.PriceQuestionSlider:
                        return <SliderStep step={step} raionsStep={raionsStep}  roomsStep={roomsStep} params={params} index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />
                }
            })()}
            <div className="btn_block">
              {index > 0 ? (
                <BtnComponent
                  text={'Назад'}
                  params={params}
                  clickFunct={(e) => {stepChangeDataLayer(false, index+1); prevStep(e)}}
                  analog={true}
                />
              ) : <div></div>}
              <BtnComponent
                text={'Дальше'}
                params={params}
                clickFunct={(e) => {stepChangeDataLayer(true, index+1); nextStep(e)}}
                analog={true}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step;

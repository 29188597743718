import React, { useEffect, useState } from 'react';
import BtnComponent from '../btn-component';
import CityTitle from '../city-title';
import parse from 'html-react-parser';
import Advantages from '../advantages';
import Gallery from '../gallery';
import PlansComponent from '../plans-component';
import Popups from '../popups';
import { phoneClickDataLayer, startQuizDataLayer } from '../../utils/send-data';
import Cookies from 'js-cookie';
import Catalog from '../catalog/catalog';

const MainScreen = ({ params, nextStep }) => {
  const [showAllContent, setShow] = useState(false)
  const [cookieTitle, setCookieTitle] = useState(false)

  
  const onScroll = (event) => {
    window.removeEventListener('scroll', onScroll);
    setShow(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, [])

  useEffect(()=>{
    let title = Cookies.get('title')
    if (title) {
        while(title.indexOf('+') !== -1) {
            title = title.replace('+', ' ')
        }
        
    }
    setCookieTitle(title)
},[])
  const isMobile = () => {
    let check = false;
    if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
      check = true
    }
    return check;
  }

  const getBackground = () => {
    if (isMobile()) {
      return domainSettings.domain.background_mobile
    }
    return domainSettings.domain.background
  }
  return (
    <React.Fragment>
      <section className="container_main">
        <section className="hl_top">
          <div className="wmain">
            <div className="logo">
              <img style={{ height: 'auto', opacity: "0" }} src="/templates/plans_template/build/img/logo.png" />
            </div>
            <div className="hl_phone">
              <a
                className="roistat"
                onClick={(e) => phoneClickDataLayer(params.phone?params.phone:'+78006005523')}
                href={
                  params.phone
                    ? 'tel:' + params.phone
                    : 'tel:+78006005523'
                }
              >
                {params.phone ? params.phone : '8 800 600 55 23'}
              </a>
            </div>
          </div>
        </section>
        <section className="header" style={{ background: params.background != "" ? `url("/file-store/${getBackground()!=""?getBackground():params.background}") center / cover no-repeat` : `url("/templates/plans_template/build/img/hd_bg_0810.jpg")` }}>
          <div style={{ width: '100%', padding: '100px 0px' }}>
            <div className="hd_inn">
              <CityTitle params={params} />
              <div className="page_view main_page">
                {cookieTitle ? <h1>{cookieTitle}</h1> : <h1>{!domainSettings.title || domainSettings.title.length === 0 ? domainSettings.domain.defaultHeader && domainSettings.domain.defaultHeader.length != 0? domainSettings.domain.defaultHeader:"Недорогие новостройки" : parse(domainSettings.title)}</h1>}
                <sub></sub>
                <div className="color_text">
                  {domainSettings.domain.sub_title === undefined || domainSettings.domain.sub_title == ''
                    ? parse('Бесплатный сервис по подбору недвижимости:')
                    : parse(domainSettings.domain.sub_title)}
                  <br />
                  <ul className="header_list">
                    {domainSettings.domain.sub_title_items === undefined ||
                      domainSettings.domain.sub_title_items == ''
                      ? parse(
                        '<li>Все районы города</li><li>Подберем на ваш вкус</li>'
                      )
                      : parse(domainSettings.domain.sub_title_items)}
                  </ul>
                </div>
                <BtnComponent
                  text={'Начать поиск'}
                  params={params}
                  clickFunct={(e) => {startQuizDataLayer(); nextStep(e)}}
                />
              </div>
            </div>
          </div>
        </section>
        {domainSettings.domain.catalog?<Catalog />:""}
        {domainSettings.domain.plans.length > 0 ? <PlansComponent params={params} /> : <div></div>}
        <section className="advantages" style={{backgroundColor: domainSettings.background_color?"#"+domainSettings.background_color:""}}>
          {domainSettings.domain.advantages.length > 0 ? <Advantages /> : ""}
          {showAllContent ? <React.Fragment><Gallery params={params} />
            <div className="adv_in">
              <img src="/templates/plans_template/build/img/adv_in.png" />
              <span> {domainSettings.domain.footer_title === undefined || domainSettings.domain.footer_title == ''
                ? parse('Получите подходящие варианты в течение нескольких минут')
                : parse(domainSettings.domain.footer_title)}</span>
            </div>
            <BtnComponent
              text={'Начать быстрый поиск'}
              params={params}
              clickFunct={nextStep}
            /></React.Fragment> : <div></div>}
        </section>

        {showAllContent ? <React.Fragment><footer>
          <div className="wmain">
            <div className="f_left" style={{ float: 'right' }}>
              {domainSettings.organization.phone.toLowerCase() !== "x"?<a className="roistat" onClick={(e) => phoneClickDataLayer(domainSettings.organization.phone)} href={"tel:" + domainSettings.organization.phone}>{domainSettings.organization.phone}</a>:<></>}
            </div>
            <div className="f_right">
              <p>{domainSettings.organization.name}, <br /> {domainSettings.organization.adress}</p>
              <span>{(new Date().getFullYear())} г.</span>
            </div>
            <div className="clr"></div>
          </div>
        </footer></React.Fragment> : <div></div>}
      </section>
    </React.Fragment>
  );
};

export default MainScreen;
import React, { useEffect, useState } from "react"
import InputMask from 'react-input-mask'
import { SendData } from "../../utils"
import BtnComponent from "../btn-component"
import parse from 'html-react-parser';
import { sendLeadToDataLayer } from "../../utils/send-data";
import { Checkbox } from "../steps/checkbox-item";

const PhoneStep = ({ params, nextStep, form, setForm, raionsStep, resultStep, sendParams, roomsStep, sdachaName, setShowPolitics }) => {
    const [phone, setPhone] = useState("")
    const [error, setError] = useState(false)
    const [errorChBox, setErrorChBox] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [checkbox, setChecked] = useState(false)
    const urlParams = new URLSearchParams(window.location.search);
    const inputChange = (event) => {
        let value = event.target.value

        if (domainSettings.domain.mask === "") {
            if (value.length > 4 && value.slice(0, 5) != "+7 (9") {
                value = "+7 ("
            }
        }
        setPhone(value)
        setForm({
            ...form,
            phone: value
        })
    }

    useEffect(() => {
        if (form.phone !== "" && form.phone !== undefined) {
            setPhone(form.phone)
        }
    }, [])

    const continueClick = (event) => {
        event.preventDefault()
        if (!checkbox) {
            setErrorChBox(true)
            return
        } else {
            setErrorChBox(false)
        }
        if (blocked) {
            return
        }
        if ((domainSettings.domain.mask === "" && phone.length === 18) || (domainSettings.domain.mask !== "" && phone.length === domainSettings.domain.mask.length - 1) || (urlParams.has('mask') && phone.length > 8)) {
            setError(false)
            if (domainSettings.domain.validate_phone) {
                setBlocked(true)
                fetch("/lead/validator/" + form.phone)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        console.log(data);
                        if (data.valid) {
                            SendData(form, setForm, () => nextStep(event), "getForm", false, sendParams)
                        } else {
                            resultStep(event)
                            setBlocked(false)
                            SendData(form, setForm, () => nextStep(event), "getForm", true, sendParams)
                        }
                    });
            } else {
                SendData(form, setForm, () => nextStep(event), "getForm", false, sendParams)
            }
        } else {
            console.log('error')
            let info = sendLeadToDataLayer(form, true) //send error to data layer
            dataLayer.push(info)
            setError(true)
        }
    }
    return <React.Fragment>
        <div className="title_inner">{domainSettings.domain.phone_step_title === undefined || domainSettings.domain.phone_step_title == ''
            ? parse('Подобрано <b style={{ color: `#${params.main_color}` }}>135 вариантов</b> по вашим параметрам. Есть предложения с выгодными акциями и скидками!')
            : parse(domainSettings.domain.phone_step_title)}
        </div>
        <div className="block_phone">
            <div className="bp_title">Для получения доступа к базе укажите свой номер телефона</div>
            <div className="bp_inner" style={{marginBottom: "15px"}}>
                <form className="bp_2">
                    <InputMask mask={domainSettings.domain.mask === "" ? "+7\ (999) 999-99-99" : domainSettings.domain.mask} name="phone" value={phone} onChange={inputChange} maskChar={null} >
                        {(inputProps) => <input className={error ? "in_phone ym-record-keys err" : "in_phone ym-record-keys"}  {...inputProps} placeholder={domainSettings.domain.mask === "" ? "+7 ( ___ ) ___ - __ - __" : ""} />}
                    </InputMask>
                    <BtnComponent text={"Посмотреть предложения"} params={params} clickFunct={continueClick} analog={true} />
                </form>
            </div>
            <Checkbox params={params} name="tac" checked={checkbox} onChange={() => setChecked(!checkbox)} style={{ display: "flex", }}>
                <span style={{color: errorChBox?"red":"white"}}>я даю согласие на обработку персональных данных <br /> в соответствии с <a href="#" onClick={(e)=>{e.preventDefault();  setShowPolitics(true)}} target="_blank" style={{color: errorChBox?"red":"#FFF"}}>политикой конфиденциальности</a></span>
            </Checkbox>
        </div>
    </React.Fragment>
}

export default PhoneStep
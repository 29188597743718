export const Catalog = () => {
    return (
        <section className='catalog plr'>
            <div className='wmain'>
                <div className='tm'>
                    Скачать каталог лучших объектов Дубая
                </div>
                <ul className='catalog_list'>
                    <li>
                        <a className='catalog_item' href='#'>
                            <div className='catalog_item_img'><img src='/templates/plans_template/build/img/cat_list_img1.jpg' /></div>
                            <div className='catalog_item_name'>
                                <img src='/templates/plans_template/build/img/building_ico.svg' />
                                <span>Каталог недвижимости для инвестиций</span>
                            </div>
                            <div className='btn_main'>
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.375 4.46875V0.75H0.90625C0.523438 0.75 0.25 1.05078 0.25 1.40625V14.0938C0.25 14.4766 0.523438 14.75 0.90625 14.75H10.0938C10.4492 14.75 10.75 14.4766 10.75 14.0938V5.125H7.03125C6.64844 5.125 6.375 4.85156 6.375 4.46875ZM8.45312 10.2656L5.82812 12.8906C5.63672 13.0547 5.33594 13.0547 5.14453 12.8906L2.51953 10.2656C2.24609 9.99219 2.4375 9.5 2.82031 9.5H4.625V7.3125C4.625 7.09375 4.81641 6.875 5.0625 6.875H5.9375C6.15625 6.875 6.375 7.09375 6.375 7.3125V9.5H8.15234C8.53516 9.5 8.72656 9.99219 8.45312 10.2656ZM10.5586 3.62109L7.87891 0.941406C7.74219 0.832031 7.57812 0.75 7.41406 0.75H7.25V4.25H10.75V4.08594C10.75 3.92188 10.668 3.75781 10.5586 3.62109Z" fill="#fff" />
                                </svg>
                                Скачать каталог
                            </div>
                        </a>
                    </li>
                    <li>
                        <a className='catalog_item' href='#'>
                            <div className='catalog_item_img'><img src='/templates/plans_template/build/img/cat_list_img2.jpg' /></div>
                            <div className='catalog_item_name'>
                                <img src='/templates/plans_template/build/img/building_ico.svg' />
                                <span>Каталог недвижимости для жизни в Дубае</span>
                            </div>
                            <div className='btn_main'>
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.375 4.46875V0.75H0.90625C0.523438 0.75 0.25 1.05078 0.25 1.40625V14.0938C0.25 14.4766 0.523438 14.75 0.90625 14.75H10.0938C10.4492 14.75 10.75 14.4766 10.75 14.0938V5.125H7.03125C6.64844 5.125 6.375 4.85156 6.375 4.46875ZM8.45312 10.2656L5.82812 12.8906C5.63672 13.0547 5.33594 13.0547 5.14453 12.8906L2.51953 10.2656C2.24609 9.99219 2.4375 9.5 2.82031 9.5H4.625V7.3125C4.625 7.09375 4.81641 6.875 5.0625 6.875H5.9375C6.15625 6.875 6.375 7.09375 6.375 7.3125V9.5H8.15234C8.53516 9.5 8.72656 9.99219 8.45312 10.2656ZM10.5586 3.62109L7.87891 0.941406C7.74219 0.832031 7.57812 0.75 7.41406 0.75H7.25V4.25H10.75V4.08594C10.75 3.92188 10.668 3.75781 10.5586 3.62109Z" fill="#fff" />
                                </svg>
                                Скачать каталог
                            </div>
                        </a>
                    </li>
                    <li>
                        <a className='catalog_item' href='#'>
                            <div className='catalog_item_img'><img src='/templates/plans_template/build/img/cat_list_img3.jpg' /></div>
                            <div className='catalog_item_name'>
                                <img src='/templates/plans_template/build/img/building_ico.svg' />
                                <span>Как купить недвижимость в Дубае</span>
                            </div>
                            <div className='btn_main'>
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.375 4.46875V0.75H0.90625C0.523438 0.75 0.25 1.05078 0.25 1.40625V14.0938C0.25 14.4766 0.523438 14.75 0.90625 14.75H10.0938C10.4492 14.75 10.75 14.4766 10.75 14.0938V5.125H7.03125C6.64844 5.125 6.375 4.85156 6.375 4.46875ZM8.45312 10.2656L5.82812 12.8906C5.63672 13.0547 5.33594 13.0547 5.14453 12.8906L2.51953 10.2656C2.24609 9.99219 2.4375 9.5 2.82031 9.5H4.625V7.3125C4.625 7.09375 4.81641 6.875 5.0625 6.875H5.9375C6.15625 6.875 6.375 7.09375 6.375 7.3125V9.5H8.15234C8.53516 9.5 8.72656 9.99219 8.45312 10.2656ZM10.5586 3.62109L7.87891 0.941406C7.74219 0.832031 7.57812 0.75 7.41406 0.75H7.25V4.25H10.75V4.08594C10.75 3.92188 10.668 3.75781 10.5586 3.62109Z" fill="#fff" />
                                </svg>
                                Скачать каталог
                            </div>
                        </a>
                    </li>
                    <li>
                        <a className='catalog_item' href='#'>
                            <div className='catalog_item_img'><img src='/templates/plans_template/build/img/cat_list_img4.jpg' /></div>
                            <div className='catalog_item_name'>
                                <img src='/templates/plans_template/build/img/building_ico.svg' />
                                <span>Гайд как получить вид на жительство в Дубае</span>
                            </div>
                            <div className='btn_main'>
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.375 4.46875V0.75H0.90625C0.523438 0.75 0.25 1.05078 0.25 1.40625V14.0938C0.25 14.4766 0.523438 14.75 0.90625 14.75H10.0938C10.4492 14.75 10.75 14.4766 10.75 14.0938V5.125H7.03125C6.64844 5.125 6.375 4.85156 6.375 4.46875ZM8.45312 10.2656L5.82812 12.8906C5.63672 13.0547 5.33594 13.0547 5.14453 12.8906L2.51953 10.2656C2.24609 9.99219 2.4375 9.5 2.82031 9.5H4.625V7.3125C4.625 7.09375 4.81641 6.875 5.0625 6.875H5.9375C6.15625 6.875 6.375 7.09375 6.375 7.3125V9.5H8.15234C8.53516 9.5 8.72656 9.99219 8.45312 10.2656ZM10.5586 3.62109L7.87891 0.941406C7.74219 0.832031 7.57812 0.75 7.41406 0.75H7.25V4.25H10.75V4.08594C10.75 3.92188 10.668 3.75781 10.5586 3.62109Z" fill="#fff" />
                                </svg>
                                Скачать каталог
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Catalog
import axios from "axios"
import Cookies from "js-cookie";
import jsCookie from "js-cookie"
import md5Hex from 'md5-hex';
import { QuestionType } from "../components/steps/enums";

const sendLeadUrl = "/lead"
const updateLeadUrl = "/lead"
const getParam = (p) => {
	var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
	return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const downloadFile = (url) => {
	let filename = domainSettings.domain.url+ ".pdf";
	var link = document.createElement('a');
	link.href = url;
	link.download = filename;
	link.setAttribute('target', '_blank')
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

const SendData = (form, setForm, callback, celtype, sendToCc = false, sendParams) => {

	let dataLayerInfo = sendLeadToDataLayer(form, false)
	let answers = getAnswersFromForm(form)


	let leadgen = {
		"AMO_ID": "false",
		"loc_type": "",
		"roistatVisitId": jsCookie.get('roistat_visit'),
		"act": "tmp",
		"vtor": "0",
		"jk_id": "",
		"TEMA": "Заявка с одноэкранника",
		"m_codes": "",
		"f_info[]": [],
		"yclid": getParam('yclid') == null ? "" : getParam('yclid'),
		"gclid": getParam('gclid') == null ? "" : getParam('gclid'),
		"send_type": "",
		"l_t": "la",
		"set_bd": "1",
		"s[sid]": "",
		"s[d]": domainSettings.domain.url,
		"s[r]": "",
		"s[uri]": decodeURIComponent(window.location.search),
		"fb_id": domainSettings.domain.facebook,
		"metrika_id": domainSettings.domain.yandex,
		"ut_type": domainSettings.domain.url,
		"s[ua]": window.navigator.userAgent,
		"a_sda4a[]": answers.sdacha,
		//"r_codes": answers.raions,
		"clientID": domainSettings.domain.clientID,
		"_fbp": jsCookie.get("_fbp"),
		"_fbc": jsCookie.get("_fbc"),
		"ga_cid": googleClientID(jsCookie.get("_ga")),
		"S_ID": domainSettings.city.portal_id,
		"s_name": domainSettings.city.Name,
		"s[ip]": domainSettings.ip,
		"user_ip": domainSettings.ip,
		"utm_content": "1ekran",
		"name": "",
		"phone": "",
		"celType": "",
		"s[cel]": "",
	}

	if (sendParams.pipelineId) {
		leadgen["pipelineId"] = sendParams.pipelineId
	}

	if (sendParams.statusId) {
		leadgen["statusId"] = sendParams.statusId
	}

	if (sendParams.sId) {
		leadgen["S_ID"] = sendParams.sId
	}

	if (getParam('utm_campaign') != null) {
		leadgen["utm_campaign"] = getParam('utm_campaign')
	}
	if (getParam('utm_medium') != null) {
		leadgen["utm_medium"] = getParam('utm_medium')
	}
	if (getParam('utm_term') != null) {
		leadgen["utm_term"] = getParam('utm_term')
	}
	if (getParam('utm_source') != null) {
		leadgen["utm_source"] = getParam('utm_source')
	}
	if (getParam('send') != null) {
		leadgen["utm_send"] = getParam('send')
	}

	if (getParam('utm_send') != null) {
		leadgen["utm_send"] = getParam('utm_send')
	}

	if (!form['jk_id']) {
		if (domainSettings.domain.send_to_call_center || sendToCc) {
			leadgen["utm_send"] = "cc"
		}

		if (sendToCc) {
			leadgen["no_orders"] = "y"
		}
	} else {
		leadgen["no_orders"] = "y"
		leadgen["is_base"] = "y"
	}

	if (window.location.host.indexOf('localhost') !== -1) {
		console.log(leadgen)
		return
	}

	let text = []
	Object.keys(form).map((key) => {
		if (key === 'name') {
			leadgen.name = form['name']
		} else if (key === 'phone') {
			leadgen.phone = form['phone']
		} else if (key === 'pipeline_id') {
			leadgen.pipeline_id = form['pipeline_id']
		} else if (key === 'status_id') {
			leadgen.status_id = form['status_id']
		} else if (key === 'jk_id') {
			leadgen.jk_id = form['jk_id']
		} else if (key === 'jk_name') {
			leadgen.jk_name = form['jk_name']
		} else if (key !== 'lead_id') {
			var answer = form[key]
			if (typeof answer === 'object') {
				answer = form[key].join(',')
			}
			if (answer.length > 150) {
				answer = answer.substring(0,150) + " ..."
			}
			text.push(key + " - " + answer)
		}
	})
	leadgen = { ...leadgen, "f_info[]": text }


	try {
		if (!form['name'] || form['name'].length === 0) {
			dataLayer.push(dataLayerInfo)
		} else {
			sendNameDataLayer(false)
		}
	} catch (e) {
		console.log('error pushing data layer: ' + e)
	}
	if (celtype === "getForm") {
		leadgen["celType"] = "getForm"
		leadgen["s[cel]"] = "getForm"
		axios.put(sendLeadUrl + window.location.search, leadgen).then((resp) => {
			if (resp.status === 201) {
				if (domainSettings.domain.not_send_cell_for_not_valid_phone && domainSettings.domain.validate_phone && sendToCc) {
					console.log('not sending cell')
				} else {
					SendCell(celtype, leadgen.phone)
				}
			}
			setForm({
				...form,
				lead_id: resp.data.data
			})
		})
	} else {
		leadgen["celType"] = "getName"
		leadgen["s[cel]"] = "getName"
		leadgen = { ...leadgen, id: form.lead_id }
		axios.put(sendLeadUrl + window.location.search, leadgen).then((resp) => {
			if (resp.status === 201) {
				if (domainSettings.domain.not_send_cell_for_not_valid_phone && domainSettings.domain.validate_phone && sendToCc) {
					console.log('not sending cell')
				} else {
					SendCell(celtype, leadgen.phone)
				}
			}
			console.log('updated')
		})
	}

	try {
		if (domainSettings.domain.leadPdfFile && domainSettings.domain.leadPdfFile != "" && form['name'] && form['name'].length !== 0) {
			downloadFile("/file-store/" + domainSettings.domain.leadPdfFile)
		}
	} catch (e) {
		console.log("error downloadind file")
	}
	
	if (!sendToCc) {
		callback()
	}
}

const googleClientID = (cookie) => {
	if (cookie) {
		let paths = cookie.split('.')
		if (paths.length == 4) {
			paths = paths.slice(2)
		} else {
			paths = [""]
		}
		return paths.join(".")
	}
	return ""
}

const sendLeadToDataLayer = (form, error) => {
	const SITE_TEMPLATE = 'Кастомный шаблон'
	let steps = domainSettings.domain.steps
	let raions = ""
	let rooms = ""
	let sdacha = ""
	let price = ""

	steps.forEach((step) => {
		Object.keys(form).forEach((title) => {
			if (title === step.title) {
				switch (step.questionType) {
					case QuestionType.RaionsQuestion:
						raions = form[title].join(',')
						break
					case QuestionType.RoomsQuestion:
						rooms = form[title].join(',')
						break
					case QuestionType.CompletionQuestion:
						sdacha = form[title].join(',')
						break
					case QuestionType.PriceQuestionSlider:
						price = form[title]
						break
					case QuestionType.PriceQuestionVariants:
						price = form[title]
						break
				}
			}
		})

	})

	let data = {
		'event': error ? 'submit_form_error' : 'submit_form',
		'category': 'conversions',
		'action': 'send_form',
		'label': 'checkout_form',
		'district': raions,
		'room': rooms,
		'dateofdelivery': sdacha,
		'price': price,
		'currency': 'RUB',
		'sitetype': SITE_TEMPLATE,
		'city': domainSettings.city.name,
	}

	return data
}

const phoneClickDataLayer = (phone) => {
	try {
		dataLayer.push({
			'event': 'click_phone_number',
			'category': 'actions',
			'action': 'click',
			'label': phone
		});
	} catch (e) {
		console.log('error sending event to data layer: ' + e)
	}

}

const startQuizDataLayer = () => {
	try {
		dataLayer.push({
			'event': 'click_button_start',
			'category': 'actions',
			'action': 'click_button',
			'label': 'start_quiz'
		})
	} catch (e) {
		console.log('error sending event to data layer: ' + e)
	}
}

const stepChangeDataLayer = (forward, step) => {
	try {
		dataLayer.push({
			'event': forward ? 'click_button_step_' + step : 'click_button_back_step_' + step,
			'category': 'actions',
			'action': 'click_button',
			'label': forward ? 'step_' + step : 'back_step_' + step,
		});
	} catch (e) {
		console.log('error sending event to data layer: ' + e)
	}
}

const clickLinkDataLayer = (jkName) => {
	try {
		dataLayer.push({
			'event': 'click_links',
			'category': 'links',
			'action': 'click_links',
			'label': jkName
		});
	} catch (e) {
		console.log('error sending event to data layer: ' + e)
	}
}

const sendNameDataLayer = (error) => {
	try {
		dataLayer.push({
			'event': error ? 'click_button_get_compilation_error' : 'click_button_get_compilation',
			'category': 'actions',
			'action': 'click_button',
			'label': 'get_compilation'
		});
	} catch (e) {
		console.log('error sending event to data layer: ' + e)
	}
}

const generateParamsForUrl = (form) => {
	let query = "?utm_content=1ekran"
	if (getParam('utm_campaign') != null) {
		query += "&utm_campaign=" + getParam('utm_campaign')
	}
	if (getParam('utm_medium') != null) {
		query += "&utm_medium=" + getParam('utm_medium')
	}
	if (getParam('utm_term') != null) {
		query += "&utm_term=" + getParam('utm_term')
	}
	if (getParam('utm_source') != null) {
		query += "&utm_source=" + getParam('utm_source')
	}
	if (getParam('send') != null) {
		query += "&utm_send=" + getParam('send')
	}
	if (getParam('utm_send') != null) {
		query += "&utm_send=" + getParam('utm_send')
	}
	query += "&roistatVisitId=" + jsCookie.get('roistat_visit') + "&utm_phone=" + form['phone']
	return query
}

const SendCell = (celType, phone) => {
	console.log("celType " + celType);
	try {
		if (celType == "form" || celType == "getForm") {
			VK.Goal("lead");
			console.log("VK set lead");
		}
	} catch (err) {
		console.log("err VK set lead");
	}

	try {
		if (celType == "form" || celType == "getForm") {
			console.log("fb set Lead {content_name : " + celType + "}");
			let event_id = phone;
			event_id = event_id.split(' ').join('');
			event_id = event_id.replace(/[^0-9]/gim, '');
			console.log("fb set Lead {event_id : " + event_id + "}");
			fbq('track', "Lead", { content_name: "form", 'event_id': md5Hex(event_id) });
		}
	} catch (err) {
		console.log("err fb set Lead");
	}

	try {
		console.log("GA set generate_lead");
		gtag('event', 'generate_lead', {});
	} catch (err) {
		console.log("err GA set generate_lead");
	}

	console.log("YA set " + celType);
	try {
		ym(parseInt(domainSettings.domain.yandex), 'reachGoal', celType);
	} catch (err) {
		console.log('error send to yandex')
	}

	try {
		let cookieVal = Cookies.get('testing')
		if (cookieVal && celType == "getForm") { //&& Cookies.get('unique')
			ym(parseInt(domainSettings.domain.yandex), 'reachGoal', cookieVal == window.location.host ? "a_site" : "b_site");
		}
	} catch (err) {
		console.log('teting error send to yandex')
	}

	try {
		ym(domainSettings.domain.yandex, 'reachGoal', 'vse');
	} catch (err) {

	}

	try {
		if (celType == "form" || celType == "getForm") {
			console.log(`top mail sended ${domainSettings.domain.mail}`);
			_tmr.push({ id: `${domainSettings.domain.mail}`, type: 'reachGoal', goal: 'all_cells' });
		}
	} catch (err) {
		console.log(`err top mail sended ${domainSettings.domain.mail}`);
	}
}



const GetJKList = async (form) => {
	let data = getFilterParamsFromForm(form)
	data.cityId = domainSettings.city.id
	let resp = await axios.post("/jks/", data)
	return resp.data
}

function getAnswersFromForm(form) {
	let answers = {
		raions: [],
		rooms: [],
		sdacha: [],
		price: [],
	}

	domainSettings.domain.steps.forEach((step) => {
		Object.keys(form).forEach((title) => {
			if (title === step.title) {
				switch (step.questionType) {
					case QuestionType.RaionsQuestion:
						answers.raions = form[title]
						break
					case QuestionType.RoomsQuestion:
						answers.rooms = form[title]
						break
					case QuestionType.CompletionQuestion:
						answers.sdacha = form[title]
						break
					case QuestionType.PriceQuestionSlider:
						answers.price = form[title]
						break
					case QuestionType.PriceQuestionVariants:
						answers.price = form[title]
						break
					case QuestionType.DistrictQuestion:
						answers.raions = form[title]
						break
				}
			}
		})

	})

	return answers
}

function getFilterParamsFromForm(form) {
	let filter = {
		raions: [],
		rooms: [],
		sdacha: [],
		price: [Number.MAX_SAFE_INTEGER, -1],
		class: [],
	}

	domainSettings.domain.steps.forEach((step) => {
		Object.keys(form).forEach((title) => {
			if (title === step.title) {
				switch (step.questionType) {
					case QuestionType.RaionsQuestion:
					case QuestionType.DistrictQuestion:
						form[title].forEach((answer) => {
							let param = getFilterParamFromStep(step, answer)
							if (typeof param == "object") {
								filter.raions.push(...param)
							} else if (param !== null) {
								filter.raions.push(param)
							}
						})
						break
					case QuestionType.RoomsQuestion:
						form[title].forEach((answer) => {
							let param = getFilterParamFromStep(step, answer)
							if (param !== null) {
								filter.rooms.push(param)
							}
						})
						break
					case QuestionType.CompletionQuestion:
						form[title].forEach((answer) => {
							let param = getFilterParamFromStep(step, answer)
							if (param !== null) {
								filter.sdacha.push(param)
							}
						})
						break
					case QuestionType.PriceQuestionSlider:
						filter.price[0] = 0
						filter.price[1] = form[title]
						break
					case QuestionType.ClassQuestion:
						form[title].forEach((className) => {
							let paramClass = getFilterParamFromStep(step, className)
							filter.class.push(paramClass)
							return
						})
						break
					case QuestionType.PriceQuestionVariants:
						form[title].forEach((answer) => {
							let param = getFilterParamFromStep(step, answer)
							if (param[0] < filter.price[0]) {
								filter.price[0] = param[0]
							}
							if (filter.price[1] !== 0 && (param[1] > filter.price[1] || filter.price[1] === 0)) {
								filter.price[1] = param[1]
							}
						})
				}
			}
		})
	})

	if (filter.price[1] < 0) {
		filter.price[1] = 0
	}
	if (filter.price[0] === Number.MAX_SAFE_INTEGER) {
		filter.price[0] = 0
	}

	return filter
}

function getFilterParamFromStep(step, answerText) {
	let result = null
	step.answers.forEach((answer) => {
		if (answer.title === answerText) {
			result = answer.param
		}
	})

	return result
}

export {
	SendData,
	GetJKList,
	generateParamsForUrl,
	phoneClickDataLayer,
	startQuizDataLayer,
	stepChangeDataLayer,
	clickLinkDataLayer,
	sendNameDataLayer,
	sendLeadToDataLayer,
}
import React, { useEffect, useState } from "react"
import { SendData } from "../../utils"
import { sendNameDataLayer } from "../../utils/send-data"
import BtnComponent from "../btn-component"

const NameStep = ({ params, nextStep, form, setForm, raionsStep, roomsStep, sendParams, sdachaName }) => {
    const [name, setName] = useState("")
    const [error, setError] = useState(false)
    const inputChange = (event) => {
        setName(event.target.value)
        setForm({
            ...form,
            name: event.target.value
        })
    }

    useEffect(() => {
        if (form.name !== "" && form.name !== undefined) {
            setName(form.name)
        }
    }, [])

    const continueClick = (event) => {
        event.preventDefault()
        if (name.length !== 0) {
            setError(false)
            SendData(form, setForm, () => nextStep(event), "getName", false, sendParams)
        } else {
            setError(true)
            sendNameDataLayer(true)
        }
    }

    return (<React.Fragment>
        <div className="title_inner">Как вас зовут?</div>
        <form class="form_main" onSubmit={(e)=>{e.preventDefault()}}>
            <div class="form_sub">Ваше Имя</div>
            <div className="bp_2 bp_2_end">
                <input className={error ? "err in_name" : "in_name"} onChange={inputChange} type="text" value={name} />
                <BtnComponent text={"Получить подборку"} params={params} clickFunct={continueClick} analog={true} />
            </div>
        </form>

    </React.Fragment>)
}

export default NameStep
import React, { useEffect, useState } from 'react';

const Advantages = () => {
  const [advantages, setAdvantages] = useState([]);
  useEffect(() => {
    setAdvantages(domainSettings.domain.advantages);
  }, [domainSettings.domain.advantages]);

  return (
    <React.Fragment>
      <div className="tm">
        {domainSettings.domain.advantages_alt?<div className='tm_ico'><img src='/templates/plans_template/build/img/adv_title.png'/></div>:<></>}
        {domainSettings.domain.advantages_title}
      </div>
      <div className={domainSettings.domain.advantages_alt?"adv_list adv_list2":"adv_list"}>
        <ul>
          {advantages.map((advantage) => {
            return (
              <li key={advantage.name}>
                <img className={domainSettings.domain.advantages_alt?"radius0":""} src={"/file-store/" + advantage.image} />
                {domainSettings.domain.advantages_alt?<>
                  <span>
                    {advantage.name.split("|")[0]}
                    <b style={{background: "url(/templates/plans_template/build/img/adv_list_ico.png) no-repeat left top;"}}></b>
                  </span>
                  {advantage.name.split("|")[1]?<i>{advantage.name.split("|")[1]}</i>:<></>}
                </>:<span>{advantage.name}</span>}
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Advantages;
